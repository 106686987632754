<template>
	<div id="top" class="mt20 ortho-results-container">
		<v-row xl="6" md="8" sm="12" cols="12" justify="center" class="mt20 mb20">
			<v-col xl="6" md="8" sm="12" cols="12">
				<div class="onboarding-progress-bar-step-div">
					<div class="onboarding-progress-bar-step-sub-div">
						<v-progress-linear
							v-model="stepProgressBarBufferValue"
							:buffer-value="stepProgressBar"
							striped
							rounded
							color="#15c39a"
							height="9"
						></v-progress-linear>
					</div>
				</div>
				<h1 class="mb20">
					Rapport personnel de
					<span class="ortho-text-green">{{ userFirstName }}</span>
				</h1>
				<v-divider></v-divider>
				<v-col xl="12" md="12" sm="12" cols="12">
					<h1>Ton score :</h1>
					<div class="ortho-bold ortho-results-score mb20 animate__animated animate__jackInTheBox">
						{{ score }} / 40
					</div>
					<v-chip color="#15c39a" text-color="white">
						<v-icon size="20px" color="white">mdi-information-outline</v-icon>
						<h4 class="ml5">Tu fais mieux que {{ scorePercentage() - 8 }}% des utilisateurs</h4>
					</v-chip>
				</v-col>
				<img class="image-results-report" src="@/assets/images/testpositionscore.png" alt="" />

				<table class="ortho-results-text table-results shadow-blue" style="width: 100%">
					<tr>
						<td style="width: 50%; height: 40px">Nombre de bonne(s) réponse(s)</td>
						<td style="width: 50%" class="ortho-bold ortho-text-green">
							{{ goodAnswersGiven.length }}
						</td>
					</tr>
					<tr>
						<td style="height: 40px">Nombre de mauvaise(s) réponse(s)</td>
						<!-- as not answered question are pushed in badAnswersGiven array we have to substracted the number of not answered question of badAnswersGiven array to get the number of badAnswersGiven question  -->
						<td class="ortho-bold ortho-text-red">
							{{ badAnswersGiven.length - questionWhitoutAnswer() }}
						</td>
					</tr>
					<tr>
						<td style="height: 40px">Nombre de question(s) sans réponse</td>
						<td>{{ questionWhitoutAnswer() }}</td>
					</tr>
					<tr>
						<td style="height: 40px">Nombre total de questions</td>
						<td class="ortho-bold">{{ listQuestions.length }}</td>
					</tr>
				</table>

				<div class="ortho-results-text container-explication shadow-blue pt20 pb20">
					<h2 class="mb30">Ton résultat à la loupe 🔎</h2>
					<p v-if="scorePercentage() >= 90">
						« {{ userFirstName }}, avec une note globale de {{ scorePercentage() }}%,
						<strong>tu as un niveau parfait d’employabilité vis-à-vis de vos écrits</strong>
						: tu maîtrises l’ensemble des règles de grammaire et d’orthographe ! Tu n’as pas un réel besoin de
						formation. Tu es très sûrement LA référence -pas seulement de ton entreprise- mais de tout ton entourage en
						la matière, chapeau l’artiste ! »
					</p>
					<p v-else-if="scorePercentage() >= 70">
						« {{ userFirstName }}, avec une note globale de {{ scorePercentage() }}%,
						<strong>tu as un niveau solide d’employabilité vis-à-vis de l’orthographe</strong>
						: tu maîtrises l’essentiel des règles de grammaire et d’orthographe et nul doute que tu ne laisses passer
						que peu d’erreurs dans tes écrits professionnels. Tu as néanmoins toujours une marge de progression à ta
						disposition pour devenir performant à l’écrit : en effet, certaines règles et exceptions de la langue
						française semblent encore t’échapper, mais plus pour longtemps »
					</p>
					<p v-else-if="scorePercentage() >= 50">
						« {{ userFirstName }}, avec une note globale de {{ scorePercentage() }}%,
						<strong>tu as un niveau normal d’employabilité vis-à-vis de l’orthographe</strong>
						: tu maîtrises les règles basiques de grammaire et d’orthographe. Tu as tout de même une importante marge de
						progression à ta disposition pour devenir performant à l’écrit : certaines règles et exceptions de la langue
						française semblent encore t'échapper, mais plus pour longtemps »
					</p>
					<p v-else>
						« {{ userFirstName }}, avec une note globale de {{ scorePercentage() }}%,
						<strong>tu as un niveau d’aptitude fragile en orthographe</strong>. Tu maîtrises les règles basiques de
						grammaire mais l’orthographe est souvent pour toi plus affaire d’instinct que de certitude… Cela peut donc
						te mener à la faute ! Tu as ainsi une importante marge de progression à ta disposition pour devenir
						performant à l’écrit. Certaines règles et exceptions de la langue vous échappent, mais plus pour longtemps »
					</p>
					<div id="chart" class="mt20 text-center hidden-sm-and-down">
						<apexchart type="radar" height="500" :options="chartOptions" :series="series"></apexchart>
					</div>
					<div class="mt20 text-center hidden-md-and-up">
						<div v-for="(element, index) in series[0].data" :key="index">
							<strong class="mb-2">{{ chartOptions.xaxis.categories[index] }}</strong>
							<v-progress-linear :value="element" height="13" color="#15c39a" rounded background-color="#F1F1F1">
								<strong class="fs10">{{ element }}%</strong>
							</v-progress-linear>
						</div>
					</div>
				</div>
				<v-alert
					color="#3370EA"
					type="success"
					prominent
					class="alert-banner text-left animate__animated animate__backInUp"
				>
					<v-row align="center">
						<v-col class="grow">
							<strong class="fs20 text-alert-banner">Ta formation est prête !</strong>
						</v-col>
						<v-col class="shrink">
							<v-btn
								color="white"
								@click="goToExercise()"
								class="white black--text animate__animated animate__pulse animate__faster animate__infinite"
								>COMMENCER</v-btn
							>
						</v-col>
					</v-row>
				</v-alert>
				<div class="correction-container pa15">
					<h2 class="mb10">Tes réponses</h2>
					<img src="@/assets/icons/arrow-down.svg" class="arrow-icon mb20" alt="" />
					<v-row v-for="(i, index) in listQuestions.length" v-bind:key="i">
						<v-col xl="12" md="12" sm="12" cols="12">
							<v-card
								class="mx-auto shadow-blue mb30"
								:class="{
									pa7: $vuetify.breakpoint.smAndDown,
									pa30: $vuetify.breakpoint.mdAndUp,
								}"
								max-width="100%"
							>
								<span class="ortho-bold pb20">-{{ index + 1 }}-</span>
								<br />
								<br />
								<span class="question-result-title ortho-bold">{{ listQuestions[index].libelle }}</span>
								<br />
								<br />
								<span class="question-action">{{ listQuestions[index].action }}</span>
								<br />
								<br />
								<v-row v-if="listQuestions[index].answers[listQuestions[index].good_answer] == answersGiven[index]">
									<v-col>
										<span>
											Ta réponse :
											<span class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct">{{
												answersGiven[index]
											}}</span>
										</span>
										<v-divider class="mt-3 mb-3"></v-divider>
										<v-expansion-panels>
											<v-expansion-panel>
												<v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
												<v-expansion-panel-content>
													<div class="text-left">
														<div v-for="(cour, index) in listQuestions[index].cours_id" :key="'description-' + index">
															<p class="mt-2 ortho-bold ortho-text-green">
																{{ cour.sub_description }}
															</p>
															<p v-html="cour.description"></p>
														</div>
													</div>
												</v-expansion-panel-content>
											</v-expansion-panel>
										</v-expansion-panels>
									</v-col>
								</v-row>
								<v-row v-else>
									<v-col>
										<p class="mb-0">
											Bonne réponse :
											<span class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct">{{
												listQuestions[index].answers[listQuestions[index].good_answer]
											}}</span>
										</p>
										<p class="mb-0">
											Ta réponse :
											<span
												class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-incorrect ortho-questions-answers-incorrect-border"
												>{{ answersGiven[index] }}</span
											>
										</p>
										<br />
										<v-divider class="mt-3 mb-3"></v-divider>
										<v-expansion-panels>
											<v-expansion-panel>
												<v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
												<v-expansion-panel-content>
													<div class="text-left">
														<div v-for="(cour, index) in listQuestions[index].cours_id" :key="'description-' + index">
															<p class="mt-2 ortho-bold ortho-text-red">
																{{ cour.sub_description }}
															</p>
															<p v-html="cour.description"></p>
														</div>
													</div>
												</v-expansion-panel-content>
											</v-expansion-panel>
										</v-expansion-panels>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
					<div class="hvr-grow ortho-next-button-nobackground mb20" v-scroll-to="{ el: '#top', offset: -200 }">
						Revenir en haut de page
						<v-icon class="ortho-icon-black fs15 ml5">fas fa-arrow-up</v-icon>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
	name: 'ResultsDetail',
	components: {
		apexchart: VueApexCharts,
	},
	computed: {
		dateDisplay() {
			if (moment().format('e') == 5 || moment().format('e') == 6) {
				return 'lundi';
			} else {
				return 'demain';
			}
		},
		...mapGetters('profile', {
			formationInfos: 'formationInfos',
		}),
	},
	props: {
		score: Number,
		userFirstName: String,
		listQuestions: Array,
		answersGiven: Array,
		goodAnswersGiven: Array,
		badAnswersGiven: Array,
		trial: String,
	},
	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 80,
			stepProgressBarLimit: 90,

			datasetAnswer: [this.goodAnswersGiven, this.answersGiven],
			series: [
				{
					name: 'Votre audit',
					data: [10, 35, 35, 10, 25, 20],
				},
			],
			chartOptions: {
				chart: {
					height: 500,
					type: 'radar',
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val + '%';
					},
					style: {
						fontSize: '14px',
					},
				},
				colors: ['#15c39a'],
				plotOptions: {
					radar: {
						polygons: {
							strokeColor: '#e9e9e9',
							fill: {
								colors: ['#f8f8f8', '#fff'],
							},
						},
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val + '/100';
						},
					},
				},
				xaxis: {
					categories: ['Orthographe', 'Homonymes', 'Grammaire', 'Syntaxe', 'Conjugaison', 'Accords'],
					labels: {
						style: {
							fontSize: '15px',
						},
					},
				},
				yaxis: {
					max: 100,
					min: 0,
					tickAmount: 10,
					labels: {
						formatter: function (val, i) {
							if (i % 2 === 0) {
								return Math.round(val) + '%';
							} else {
								return '';
							}
						},
					},
				},
			},
		};
	},
	async mounted() {
		await this.$store.dispatch('profile/getProfileFormationInfos', { formationId: this.$route.params.idFormation });
		//start progress bar animation
		this.startBuffer();

		//always display top of the page
		window.scrollTo(0, 0);

		//update chart
		if (this.score >= 35) {
			const max = 80;
			const min = 90;

			const newData = this.series[0].data.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});

			this.series = [
				{
					data: newData,
				},
			];
		} else if (this.score >= 30 && this.score < 35) {
			const max = 80;
			const min = 60;

			const newData = this.series[0].data.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});

			this.series = [
				{
					data: newData,
				},
			];
		} else if (this.score >= 20 && this.score < 30) {
			const max = 60;
			const min = 40;

			const newData = this.series[0].data.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});

			this.series = [
				{
					data: newData,
				},
			];
		} else if (this.score >= 10 && this.score < 20) {
			const max = 50;
			const min = 25;

			const newData = this.series[0].data.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});

			this.series = [
				{
					data: newData,
				},
			];
		} else {
			const max = 40;
			const min = 20;

			const newData = this.series[0].data.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});

			this.series = [
				{
					data: newData,
				},
			];
		}
		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('testPositionnement-resultsPage');
		}
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
		scorePercentage() {
			return ((this.score / 40) * 100).toFixed();
		},
		questionWhitoutAnswer() {
			//not answered question are pushed in badAnswersGiven array so we have to filter to get the number of question not answered
			var isNotAnswered = this.badAnswersGiven.filter(function (badAnswersGiven) {
				return badAnswersGiven === 'Non répondu';
			});
			return isNotAnswered.length;
		},
		// goToDashboard() {
		//   let routeData = this.$router.resolve("/dashboard");
		//   if (process.env.VUE_APP_MIXPANEL == "production") {
		//     this.$mixpanel.track("testPositionnement-goToDashboard");
		//   }
		//   return window.open(routeData.href, "_blank");
		// },
		goToExercise() {
			const routeData = this.$router.resolve(
				'/exercices/' + this.formationInfos._id + '/' + this.formationInfos.last_exercice
			);
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('testPositionnement-goToExercise');
			}
			return window.open(routeData.href, '_blank');
		},
	},
};
</script>
<style scoped>
.ortho-next-button-nobackground {
	cursor: pointer;
}
.image-results-report {
	margin-top: 70px;
	margin-bottom: 40px;
	width: 100%;
}
.arrow-icon {
	width: 2%;
	color: #15c39a !important;
}
.startnow {
	color: black;
}
.alert-banner {
	margin-left: 5px;
	margin-right: 5px;
	position: -webkit-sticky;
	position: sticky;
	top: 10px;
	bottom: 10px;
	z-index: 10000;
}

.question-action {
	color: grey;
}
.question-result-title {
	font-size: 30px;
}
.table-results,
th,
td {
	margin-top: 30px;
	border: 1px solid lightgrey;
	border-collapse: collapse;
}
.container-explication {
	line-height: 2;
	border-radius: 5px;
	border: 2px solid #15c39a;
	margin-top: 50px;
	margin-bottom: 40px;
	padding-left: 10%;
	padding-right: 10%;
}

@media only screen and (max-width: 600px) {
	.question-result-title {
		font-size: 20px;
	}
	.image-results-report {
		margin-top: 70px;
		margin-bottom: 70px;
		width: 90%;
	}
	.alert-banner {
		top: 10px;
		bottom: 10px;
	}
	.text-alert-banner {
		/* overwrite the fs20 on desktop */
		font-size: 15px !important;
	}
}
@media only screen and (max-width: 325px) {
	h3 {
		font-size: 13px;
	}
}
</style>
