<template>
	<v-container v-if="listQuestions">
		<v-row class="mb-6" justify="center" no-gutters v-if="count < listQuestions.length">
			<v-progress-circular
				class="chrono"
				:rotate="-90"
				:size="100"
				:width="15"
				:value="(100 * timeBeforeNextQuestion) / 30"
				:color="changeColor()"
			>
				{{ timeBeforeNextQuestion }}
			</v-progress-circular>

			<v-col class="test-position-container" xl="6" md="12" sm="12" cols="12">
				<div class="ortho-bold test-position-question">
					<span>{{ listQuestions[count].libelle }}</span>
				</div>
				<div class="test-position-action-container">
					<p>{{ listQuestions[count].action }}</p>
				</div>
				<div class="test-position-answers-container">
					<fade-transition group>
						<div
							class="hvr-bounce-in test-position-answers"
							v-for="(answer, index) in listQuestions[count].answers"
							:key="index"
							@click="answerQuestion(index, listQuestions[count].good_answer)"
						>
							{{ answer }}<br />
						</div>
					</fade-transition>
				</div>
				<div class="test-position-sectionprogress-container">
					<p>{{ count + 1 }}/{{ listQuestions.length }}</p>
				</div>
			</v-col>
		</v-row>
		<FinishQuiz
			v-if="count === listQuestions.length && listQuestions.length > 0"
			:score="score"
			:userFirstName="userFirstName"
			:listQuestions="listQuestions"
			:answersGiven="answersGiven"
			:goodAnswersGiven="goodAnswersGiven"
			:badAnswersGiven="badAnswersGiven"
			:trial="trial"
		></FinishQuiz>
	</v-container>
</template>

<script>
import FinishQuiz from './FinishQuiz';
import { FadeTransition } from 'vue2-transitions';

const TIME_BETWEEN_QUESTIONS = 30;

export default {
	name: 'QuestionnaireComponent',
	components: {
		FinishQuiz,
		FadeTransition,
	},
	props: {
		listQuestions: Array,
		userFirstName: String,
		trial: String,
	},
	data() {
		return {
			score: 0,
			count: 0,
			answersGiven: [],
			goodAnswersGiven: [],
			badAnswersGiven: [],
			timeBeforeNextQuestion: TIME_BETWEEN_QUESTIONS,
			questionInterval: null,
		};
	},
	created() {
		this.questionInterval = setInterval(this.nextQuestionTimeout, 1000);
		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('testPositionnement-questions');
		}
	},
	destroyed() {
		if (this.questionInterval) {
			clearInterval(this.questionInterval);
		}
	},
	methods: {
		changeColor() {
			if (this.timeBeforeNextQuestion >= 15) {
				return '#15c39a';
			} else if (this.timeBeforeNextQuestion >= 5) {
				return 'orange';
			} else {
				return '#F95F62';
			}
		},
		resetTimer() {
			clearInterval(this.questionInterval);
			this.questionInterval = setInterval(this.nextQuestionTimeout, 1000);
			this.timeBeforeNextQuestion = TIME_BETWEEN_QUESTIONS;
		},
		nextQuestionTimeout() {
			if (this.count === this.listQuestions.length) {
				//if last question not clicked
				clearInterval(this.questionInterval);
				this.$http.post('/api/positionnement-quiz/finish', {
					formationId: this.$route.params.idFormation,
					userId: this.$route.params.idUser,
					score: this.score,
					answersGiven: this.answersGiven,
					goodAnswersGiven: this.goodAnswersGiven,
					badAnswersGiven: this.badAnswersGiven,
				});
			} else if (this.timeBeforeNextQuestion === 1) {
				this.answersGiven.push('Non répondu');
				this.badAnswersGiven.push('Non répondu');
				this.count++;
				this.resetTimer();
			} else {
				this.timeBeforeNextQuestion--;
			}
		},
		answerQuestion(indexAnswer, good_answer) {
			let answer = this.listQuestions[this.count].answers[indexAnswer];
			if (indexAnswer === parseInt(good_answer)) {
				this.score++;
				this.answersGiven.push(answer);
				this.goodAnswersGiven.push(answer);
			} else {
				this.answersGiven.push(answer);
				this.badAnswersGiven.push(answer);
			}
			this.count++;
			if (this.count === this.listQuestions.length) {
				clearInterval(this.questionInterval);
				this.$http.post('/api/positionnement-quiz/finish', {
					formationId: this.$route.params.idFormation,
					userId: this.$route.params.idUser,
					score: this.score,
					answersGiven: this.answersGiven,
					goodAnswersGiven: this.goodAnswersGiven,
					badAnswersGiven: this.badAnswersGiven,
				});
			} else {
				this.resetTimer();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.scale-move {
	transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.chrono {
	position: absolute;
	right: 5%;
	max-width: 60px;
	font-weight: bold;
}
.test-position-container {
	margin-top: 130px;
}
.test-position-question {
	font-size: 50px;
	text-align: center;
}
.test-position-action-container {
	text-align: center;
	padding-top: 30px;
	font-size: 20px;
	color: grey;
}
.test-position-answers-container {
	text-align: center;
	padding-top: 5%;
	font-size: 20px;
	margin-left: 150px;
	margin-right: 150px;
}
.test-position-sectionprogress-container {
	text-align: center;
	padding-top: 10%;
}
.test-position-answers {
	display: inline-block;
	border-radius: 25px;
	margin: 15px;
	padding: 10px 25px;
	min-width: 10px;
	text-align: center;
	font-family: 'Lato';
	cursor: pointer;
	border: grey 2px solid;
	color: #000000;
	font-weight: bold;
}
/* Bounce In */
.hvr-bounce-in {
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.3s;
	&:hover {
		background-color: #6afdef;
		border-color: #6afdef;
		color: black;
		font-weight: bold;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}

@media only screen and (max-width: 600px) {
	.chrono {
		right: initial;
		position: absolute;
		max-width: 50px;
	}
	.test-position-container {
		margin-top: 90px;
	}
	.test-position-question {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 30px;
		font-size: 30px;
	}
	.test-position-action-container {
		padding-top: 50px;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 17px;
	}
	.test-position-answers-container {
		margin-left: 40px;
		margin-right: 40px;
	}
	/* removing hover effect on mobile */
	.hvr-bounce-in:hover,
	.hvr-bounce-in:focus,
	.hvr-bounce-in:active {
		background-color: initial;
		border-color: grey;
		color: initial;
		font-weight: bold;
		-webkit-transform: initial;
		transform: initial;
	}
}
</style>
